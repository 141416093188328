import React from "react";

export const shield = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M3.58193 0H20.4179C21.2428 0.00494955 21.9089 0.675075 21.9089 1.5V7.763C21.9322 14.5322 18.2091 20.7589 12.2349 23.942C12.1625 23.9803 12.0818 24.0002 11.9999 24C11.9183 24 11.8379 23.9797 11.7659 23.941C5.79093 20.7592 2.06708 14.5324 2.09093 7.763V1.5C2.09092 0.675075 2.75702 0.00494955 3.58193 0ZM10.7999 14.645L17.8999 7.945C18.1597 7.69938 18.2686 7.33385 18.1858 6.98611C18.1029 6.63836 17.8409 6.36123 17.4983 6.25911C17.1557 6.15698 16.7847 6.24538 16.5249 6.491L10.1299 12.518L8.44793 10.818C8.19808 10.5571 7.82694 10.4512 7.47705 10.5411C7.12716 10.6309 6.85292 10.9024 6.75963 11.2514C6.66635 11.6004 6.76853 11.9726 7.02693 12.225L9.39993 14.621C9.78221 15.0089 10.4046 15.0196 10.7999 14.645Z" />
    </svg>
);
