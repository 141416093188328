import React from "react";
import { Route, Switch } from 'react-router-dom';

import { Page } from "./page";
import { withRideOctaneTitle, PageTitles } from "./octane-title";
import { ApiStore } from "../stores/api-store";
import { ConsentStore } from "../stores/consent-store";
import { ConsentForm } from "../consent";
import { ReturnToServer } from "../components/return-to-server";
import { Subscription } from "../consent/subscription";

export function consentForm() {

    const store = Page.createStore({
        isConsentForm: true,
        stores: {
            apiStore: new ApiStore(),
            consentStore: new ConsentStore(),
        },
    });

    const component = Page.createComponent({
        content: (
            <Switch>
                <Route exact path={'/consent/:losApplicationUuid/:consentHash'} component={ConsentFormContent}/>
                <Route exact path={'/consent/:losApplicationUuid/:consentHash/subscription'} component={SubscriptionContent}/>
                <Route component={ReturnToServer}/>
            </Switch>
        )
    })

    return {store, component};
}

export function ConsentFormContent() {
    const titleWords = [PageTitles.CONSENT_FORM_TITLE];
    return withRideOctaneTitle(<ConsentForm/>, titleWords)
}

export function SubscriptionContent() {
    const titleWords = [PageTitles.CONSENT_FORM_TITLE];
    return withRideOctaneTitle(<Subscription/>, titleWords)
}
