import React from "react";

export const uiShow = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 24 18"
        fill="#2366B1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.722 3.841a4.499 4.499 0 1 0-3.442 8.314 4.499 4.499 0 0 0 3.442-8.314ZM12 5a3 3 0 1 1 .002 6 3 3 0 0 1-.002-6Z">
        </path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.379 6.38C20.699 3.434 16.344.434 12 .5 7.656.435 3.3 3.435.624 6.38l-.002.003a2.413 2.413 0 0 0 0 3.23l.002.002C3.24 12.498 7.584 15.566 12 15.498c4.416.069 8.76-3 11.379-5.883l.002-.002a2.413 2.413 0 0 0 0-3.23l-.002-.003ZM1.502 7.998c0-.225.083-.442.233-.61 2.563-2.82 6.54-5.45 10.252-5.387h.026c3.712-.063 7.69 2.567 10.255 5.387a.913.913 0 0 1 0 1.22c-2.511 2.763-6.477 5.454-10.255 5.39h-.026c-3.778.064-7.743-2.627-10.252-5.39a.913.913 0 0 1-.233-.61Z">
        </path>
    </svg>
);
