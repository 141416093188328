import React from "react";

export const uiHide = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="#2366B1"
        xmlns="http://www.w3.org/2000/svg"
    >

        <path d="M22.074.48a.75.75 0 0 1-.021 1.061l-18.75 18a.75.75 0 1 1-1.04-1.082l18.75-18a.75.75 0 0 1 1.061.022ZM19.144 6.438a.75.75 0 0 1 1.048-.164c1.155.843 2.223 1.8 3.186 2.858l.003.003a2.413 2.413 0 0 1 0 3.23l-.002.002c-2.62 2.883-6.965 5.953-11.382 5.883a10.734 10.734 0 0 1-3.255-.483.75.75 0 1 1 .445-1.432 9.235 9.235 0 0 0 2.808.415h.018c3.778.064 7.744-2.627 10.255-5.39a.913.913 0 0 0 0-1.22 19.824 19.824 0 0 0-2.96-2.654.75.75 0 0 1-.164-1.048ZM14.594 3.554a10.3 10.3 0 0 0-2.592-.304C7.657 3.183 3.302 6.186.624 9.13l-.002.003a2.413 2.413 0 0 0 0 3.23l.003.004a21.544 21.544 0 0 0 2.694 2.486.75.75 0 1 0 .92-1.184 20.06 20.06 0 0 1-2.504-2.312.913.913 0 0 1 0-1.219c2.563-2.818 6.54-5.45 10.253-5.388h.02a8.8 8.8 0 0 1 2.224.26.75.75 0 1 0 .362-1.456Z">
        </path>
        <path d="M12 7.75a2.999 2.999 0 0 0-3 3 .75.75 0 0 1-1.5 0 4.499 4.499 0 0 1 4.5-4.5.75.75 0 0 1 0 1.5ZM16.5 10.749a.75.75 0 1 0-1.5 0 3 3 0 0 1-3 3.001.75.75 0 0 0 0 1.5 4.501 4.501 0 0 0 4.5-4.501Z">
        </path>
    </svg>
);
