import {BaseStore} from "./base-store";
import {decorate, computed, observable, action} from "mobx";
import {formatSSN} from "../utils/number-utils";
import {validateName, validateSSN} from "../utils/validation";
import get from "lodash.get";

import template from "lodash.template";
import copy from "../consent/copy.json";


/**
 * @class ConsentStore
 *
 * Global Store for storing consent information
 *
 */

export class ConsentStore extends BaseStore {
    static FIELD_CONFIGS = {
        first_name: {
            defaultValue: "",
            validator: validateName,
            required: true,
        },
        last_name: {
            defaultValue: "",
            validator: validateName,
            required: true,
        },
        ssn: {
            defaultValue: "",
            validator: validateSSN,
            formatter: formatSSN,
            required: true,
        },
    }

    static FIELDS = Object.keys(ConsentStore.FIELD_CONFIGS);
    static FIELD_STATES = {
        VALID: 'valid',
        INVALID: 'invalid',
        UNVALIDATED: 'unvalidated',
    };

    // observables
    consentInfo = null;
    fieldStates = null;
    losApplicationUuid = null;
    disclosureConsent = false;
    // emailOptIn is false for 'unsubscribe'
    // and true for 'resubscribe'
    emailOptIn = false;
    consentHash = null;

    constructor() {
        super();
        this.consentInfo = {};
        this.fieldStates = {};

        this.constructor.FIELDS.forEach(field => {
            // initialize all fields with default value
            this.consentInfo[field] = this.constructor.FIELD_CONFIGS[field].defaultValue;
            // initialize all field states as unvalidated
            this.fieldStates[field] = this.constructor.FIELD_STATES.UNVALIDATED;
        });
    }


    /**
     * Validate field input.
     *  If there is no validator in the field config, return 'valid'.
     *
     * @param field {string}
     * @param value {string}
     * @returns {string} either 'valid' or 'invalid'
     */
    validateFieldInput(field, value) {
        const fieldValidator = this.constructor.FIELD_CONFIGS[field].validator;

        if (fieldValidator && !fieldValidator(value)) {
            return this.constructor.FIELD_STATES.INVALID;
        }

        return this.constructor.FIELD_STATES.VALID;
    }

    /**
     * Format field input if that field has a formatter in the field config.
     *  Otherwise just returns the value passed in.
     *
     * @param field {string}
     * @param value {string}
     * @returns {string} formatted input value.
     */
    formatFieldInput(field, value) {
        const fieldFormatter = this.constructor.FIELD_CONFIGS[field].formatter;

        if (!fieldFormatter) {
            return value;
        }

        return fieldFormatter(value);
    }

    /**
     * Sets up stores prior to rendering
     */
    preRenderSetup(config) {
        if (this.rideOctaneStore.isUXServer) {

            const losApplicationUuid = get(config, 'params.losApplicationUuid');
            if (losApplicationUuid) {
                // save applicationUuid to store (on server)
                this.losApplicationUuid = config.params.losApplicationUuid;
                this.consentHash = get(config, 'params.consentHash', null);
                // preRenderSetup has to be called after everything is done
                super.preRenderSetup();
                return this;
            }
        }

        return super.preRenderSetup();
    }

    /**
     * Posts consent data to API
     */
    async sendConsentInformation() {
        let consentEndPoint = `/applicant_consent`

        const options = {
            method: 'POST',
            data: this.cleanConsentInfoPayload(),
        };

        // Perform POST
        return this.apiStore.fetch(consentEndPoint, options)
            .then(({status}) =>{
                if (status !== 200) {
                    console.error(`Received status code: ${status} (expected 200) after sending consent info for LOS application ${this.losApplicationUuid}.`);
                    return {error: true};
                }
                return {error: false};
            })
    }

    async sendSubscriptionInformation() {
        let subscriptionEndPoint = '/applicant_consent/subscription';

        const options = {
            method: 'POST',
            data: this.cleanSubscriptionPayload(),
        };

        return this.apiStore.fetch(subscriptionEndPoint, options)
        .then(({status}) => {
            if (status !== 200) {
                console.error(`Received status code: ${status} 
                (expected 200) after sending subscription info for LOS application 
                ${this.losApplicationUuid}.`);
                return {error: true};
            }
            this.emailOptIn = !this.emailOptIn;
            return {error: false};
        })
    }

    cleanConsentInfoPayload = () => {
        let cleanPayload = {};

        cleanPayload.los_application_uuid = this.losApplicationUuid.trim();
        cleanPayload.hash = this.consentHash.trim()
        cleanPayload.first_name = this.consentInfo.first_name.trim();
        cleanPayload.last_name = this.consentInfo.last_name.trim();
        cleanPayload.social_security_number = formatSSN(this.consentInfo.ssn.trim());

        return cleanPayload;
    };

    cleanSubscriptionPayload = () => {
        let cleanPayload = {};

        cleanPayload.los_application_uuid = this.losApplicationUuid.trim();
        cleanPayload.hash = this.consentHash.trim();
        cleanPayload.email_preference = this.emailOptIn ? 'resubscribe' : "unsubscribe";

        return cleanPayload;
    }

    /**
     * Update a single field in the consentForm object
     *
     * @param {string} field
     * @param {*} value
     * @param {boolean} [forceUpdateFieldState] - defaults to true
     */
    updateConsentFormField(field, value, forceUpdateFieldState = true) {
        // ignore fields that are not valid consentInfo keys
        if (this.isValidConsentFormField(field)) {
            const fieldValue = this.formatFieldInput(field, value);
            const oldFieldState = this.getFieldState(field);
            const newFieldState = this.validateFieldInput(field, value);

            // update field value
            this.consentInfo[field] = fieldValue;

            // update the field state when...
            //  - forceUpdateFieldState = true (on blur of input)
            //  - the field is valid (to make sure CTA is clickable even before blur)
            //  - the field was valid but is now invalid (to make sure CTA is not clickable if it was previously)
            const shouldUpdateFieldState = (
                forceUpdateFieldState ||
                (newFieldState === this.constructor.FIELD_STATES.VALID) ||
                (
                    (oldFieldState === this.constructor.FIELD_STATES.VALID) &&
                    (newFieldState === this.constructor.FIELD_STATES.INVALID)
                )
            );
            if (shouldUpdateFieldState) {
                this.fieldStates[field] = newFieldState;
            }
        }
        return this;
    }

    updateDisclosureConsent = disclosureConsent => {
        this.disclosureConsent = disclosureConsent;
        return this;
    };

    /**
     * Returns true if field is a valid key in the consentInfo object
     */
    isValidConsentFormField = field => this.constructor.FIELDS.includes(field);
    /**
     * Returns a field's current state (e.g. UNVALIDATED, VALID or INVALID)
     */
    getFieldState = field => this.fieldStates[field];
    /**
     * Returns whether or not a field was validated and no errors were found
     */
    isFieldValid = field => this.getFieldState(field) === this.constructor.FIELD_STATES.VALID;
    /**
     * Returns whether or not a field was validated and errors were found
     */
    isFieldInvalid = field => this.getFieldState(field) === this.constructor.FIELD_STATES.INVALID;
    /**
     * Returns whether or not a field was validated
     */
    isFieldUnvalidated = field => this.getFieldState(field) === this.constructor.FIELD_STATES.UNVALIDATED;
    /**
     * Returns field items
     */
    getFieldItems = field => this.constructor.FIELD_CONFIGS[field].items || null;

    /**
     * Computed value which determines if the user can continue
     * @returns {boolean}
     */
    get canContinue() {
        // returns true if all required fields are valid and the disclosure checkbox is checked
        return (!this.constructor.FIELDS.find(field => !this.isFieldValid(field)) && this.disclosureConsent);
    }

    /**
     * Computed value which determines if partner is embedded
     * @returns {boolean}
     */
    get isEmbeddedPartner() {
        return this.partnerStore.isEmbeddedPartner;
    }

    /** Note: Name and privacy policy URL must be filled out at the partner and/or dealership level to add additional policy */
    // TODO: consolidate prequal disclosure in a sharing friendly area that can be used by both prequal and consent form
    get prequalDisclosure() {
        const dealership = this.dealershipStore.dealership;
        const partner = this.partnerStore.partner;
        let name, privacyPolicyURL;

        /* For Reveo we don't want to show either partner or dealer privacy policy as it's in their experience */
        if (!this.partnerStore.isReveo) {
            // if dealership is populated and it has privacy_policy_url set, use it to construct the disclosure
            if (dealership && dealership.name && dealership.privacy_policy_url) {
                name = dealership.name;
                privacyPolicyURL = dealership.privacy_policy_url;
            }
            // if partner is populated and it has privacy_policy_url set, use it to construct the disclosure
            else if (partner && partner.name && partner.privacy_policy_url) {
                name = partner.name;
                privacyPolicyURL = partner.privacy_policy_url;
            }
        }

        // Return the constructed disclosure
        return template(copy.prequal_disclosure.markdown_content)({
            continueButtonLabel: get(copy, 'submit_button.label'),
            name,
            privacyPolicyURL,
        });
    };
}

decorate(ConsentStore, {
    canContinue: computed,
    prequalDisclosure: computed,
    isEmbeddedPartner: computed,

    fieldStates: observable,
    consentInfo: observable,
    disclosureConsent: observable,
    emailOptIn: observable,
    consentHash: observable,

    updateConsentFormField: action,
    updateDisclosureConsent: action,
    sendConsentInformation: action,
    sendSubscriptionInformation: action,
});

