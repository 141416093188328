import React from 'react';
import {observer, inject} from 'mobx-react';
import {observable, runInAction} from 'mobx';
import {Container} from "react-bootstrap";
import {LayoutComponent} from "../components/layout-component";
import {completeMyPaperwork} from "../components/svg/graphics";
import copy from "./copy.json";
import {CTAFooter} from "../components/cta-footer";

import {SSNInput} from "../components/ssn-input";
import {Input} from "../components/input";
import {DisclosureBlock} from "../components/disclosure-block";

import {isInIFrame} from "../utils/iframe-utils";
import {SuccessScreen} from './success-screen';
import {ServerError} from "../components/interstitials";
import { FancySpinner } from '../components/spinners/fancy-spinner';

export const ConsentForm = inject('consentStore')(observer(
    class _ConsentFormBase extends React.Component {
        
        uiState = observable({
            hasSubmitted: false,
            isSubmitting: false,
            uploadError: false,
        });

        //onChange functions
        onChangeSSN = (e) => {
            this.props.consentStore.updateConsentFormField('ssn', e.target.value, e.type === 'blur')
        };
        
        onChangeFirstName = (e) => {
            this.props.consentStore.updateConsentFormField('first_name', e.target.value, e.type === 'blur')
        };

        onChangeLastName = (e) => {
            this.props.consentStore.updateConsentFormField('last_name', e.target.value, e.type === 'blur')
        };

        //onClick
        onSubmit = e => {
            runInAction(() => {
                //update the ui state
                this.uiState.isSubmitting = true;
            });
            return this.props.consentStore.sendConsentInformation()
                .then((result) => {
                    runInAction(() => {
                        this.uiState.isSubmitting = false;
                        this.uiState.hasSubmitted = true;
                        this.uiState.uploadError = result?.error;
                    })
                })
        };

        render(){
            const {consentStore} = this.props;

            if(this.uiState.isSubmitting)
                return <FancySpinner/>

            if(this.uiState.hasSubmitted){
                if(this.uiState.uploadError)
                    return <ServerError origin="consent-form"/>
                return <SuccessScreen/>
            }
            return(
                <Container>
                    <div data-oid="consent-page" className="w-315px mw-100 mx-auto">
                        <LayoutComponent
                            className="mt-5"
                            icon={completeMyPaperwork}
                            headline={copy.text.headline}
                        />
                        <div className="mt-1">
                            <Input
                                {...copy.first_name}
                                controlId="first_name"
                                value={consentStore.consentInfo.first_name}
                                error={consentStore.isFieldInvalid('first_name')}
                                onChange={this.onChangeFirstName}
                                onBlur={this.onChangeFirstName}
                            />
                        </div>
                        <div className="mt-1">
                            <Input
                                {...copy.last_name}
                                controlId="last_name"
                                value={consentStore.consentInfo.last_name}
                                error={consentStore.isFieldInvalid('last_name')}
                                onChange={this.onChangeLastName}
                                onBlur={this.onChangeLastName}
                            />
                        </div>
                        <div className="mt-1">
                            <SSNInput
                                {...copy.ssn}
                                onChange={this.onChangeSSN}
                                value={consentStore.consentInfo.ssn}
                                error={consentStore.isFieldInvalid('ssn')}
                            />
                        </div>
                        <DisclosureBlock
                            showCheckBox
                            disclosureText={consentStore.prequalDisclosure}
                            id="consent-disclosure"
                            onChange={consentStore.updateDisclosureConsent}
                        />
                        <CTAFooter
                            primary={{
                                oid: 'cta-consent-form',
                                label: copy.submit_button.label,
                                isDisabled: !consentStore.canContinue,
                                onClick: () => this.onSubmit(),
                            }}
                            isSticky={!(consentStore.isEmbeddedPartner && isInIFrame())}
                        />
                    </div>
                </Container>
            )
        }
    }
));

