import React from "react";
import {inject, observer} from 'mobx-react';
import copy from "./copy.json";
import { celebrate } from "../components/svg/graphics";
import {Container} from "react-bootstrap";
import {LayoutComponent} from "../components/layout-component";

export const SuccessScreen = () => {
    return (
        <Container>
            <LayoutComponent
                className="mt-5"
                icon={celebrate}
                headline={copy.text.success} 
                bodyText={copy.text.body}
            />
        </Container>
    )
}






