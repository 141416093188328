import React from "react";
import classnames from 'classnames';
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import {Input} from "../input";

export class DisclosureBlock extends React.Component {
    constructor(props, context) {
        super(props, context);

        if (!this.props.id && this.props.showCheckBox) {
            console.error("DisclosureBlock error: showCheckBox requires an id");
        }
    }

    handleChange = (e) => {
        this.props.onChange && this.props.onChange(e.target.checked);
    }

    LinkRenderer = props => {
        if (props.href.startsWith('http')) {
            return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>;
        }
        return <a href={props.href}>{props.children}</a>;
    };

    ParagraphRenderer = props => {
        return <p className="mb-3">{props.children}</p>
    };

    render() {
        const disclosure = (
            <ReactMarkdown
                className={`spark-text-disclosure ${this.props.showCheckBox ? "ml-2" : ""}`}
                source={this.props.disclosureText}
                renderers={{link: this.LinkRenderer, paragraph: this.ParagraphRenderer}}
            />
        );
        const wrapperClassName = classnames(
            "mt-3",
            {"d-flex align-items-start": this.props.showCheckBox},
            {"border-top pt-4": this.props.showRuleLine},
        );

        const isChecked = this.props.hasOwnProperty('isChecked') ?
        this.props.isChecked : null;

        return (
            <div className={wrapperClassName}>
                {/* Checkbox */}
                {this.props.showCheckBox &&
                    <Input type="checkbox" checked={isChecked} controlId={this.props.id} onChange={this.handleChange} />
                }
                {/* Disclosure */}
                {this.props.showCheckBox ?
                    <label htmlFor={this.props.id}>{disclosure}</label> :
                    disclosure
                }
            </div>
        );
    }
}

DisclosureBlock.propTypes = {
    disclosureText: PropTypes.string,
    // id is required for checkBox component
    id: PropTypes.string,
    showRuleLine: PropTypes.bool,
    showCheckBox: PropTypes.bool,
    onChange: PropTypes.func,
    isChecked: PropTypes.bool,
};
