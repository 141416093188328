import React from 'react';
import {observer, inject} from 'mobx-react';
import {CTAFooter} from "../components/cta-footer";
import {Container} from "react-bootstrap";
import {LayoutComponent} from "../components/layout-component";
import copy from "./copy.json";
import {observable, runInAction} from 'mobx';
import {ServerError} from '../components/interstitials';
import {isInIFrame} from "../utils/iframe-utils";

export const Subscription = inject('consentStore')(observer(
    class _SubscriptionBase extends React.Component {

        uiState = observable({
            errorSendingRequest: false,
        });

        componentDidMount() {
            this.handleSubmit();
        }

        handleSubmit = () => {
            const {consentStore} = this.props;
            return consentStore.sendSubscriptionInformation()
                .then(({error}) => {
                    runInAction(() => {
                        this.uiState.errorSendingRequest = error;
                    })
                })
        }

        render() {
            const {consentStore} = this.props;
            let bodyText;
            let additionalBody;
            let label;
            let headline;

            if (this.uiState.errorSendingRequest) {
                return <ServerError origin="subscription"/>
            }

            if (consentStore.emailOptIn) {
                headline = copy.subscription.headline.resubscribe;
                bodyText = copy.subscription.body.resubscribe;
                additionalBody = copy.subscription.additional_body.resubscribe;
                label = copy.subscription.button_label.resubscribe;
            } else {
                headline = copy.subscription.headline.unsubscribe;
                bodyText = copy.subscription.body.unsubscribe;
                additionalBody = null;
                label = null;
            }

            return(
                <Container>
                    <div data-oid="consent-subscription-page" className="w-315px mw-100 mx-auto">
                        <LayoutComponent
                            className="mt-5"
                            headline={headline}
                        />
                        <LayoutComponent
                            className="mt-5"
                            bodyText={bodyText}
                        />
                        {consentStore.emailOptIn && (
                            <>
                            <LayoutComponent
                                className="mt-5"
                                bodyText={additionalBody}
                            />
                            <CTAFooter
                                primary={{
                                    oid:'subscription-consent',
                                    onClick: this.handleSubmit,
                                    label: label,
                                    disabled: false,
                                }}
                                isSticky={!(consentStore.isEmbeddedPartner && isInIFrame())}
                            />
                            </>
                        )}
                    </div>
                </Container>
            )
        }
    }
));
