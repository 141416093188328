import React from "react";

export const directionSign = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2V23H11V2Z" />
        <path d="M3 3H13V9H3L1 6L3 3Z" />
        <path d="M21 9H11V15H21L23 12L21 9Z" />
        <path d="M8 22C8 21.4477 8.44772 21 9 21H15C15.5523 21 16 21.4477 16 22C16 22.5523 15.5523 23 15 23H9C8.44772 23 8 22.5523 8 22Z" />
    </svg>
);
