import React, {useState} from "react";

import PropTypes from "prop-types";
import {Input} from "../input";
import {uiShow, uiHide} from "../svg/visual-icons";
import {validateSSN} from "../../utils/validation";
import {formatSSN} from "../../utils/number-utils";

/**
 * Reusable component for rendering our SSN input
 *
 * @param {string}                 value - input value
 * @param {boolean}                error - whether or not the input should show the error view
 * @param {function}               onChange - onChange event handler
 * @param {boolean}                autoValidateAndFormat - when true, the input will auto-validate and format the value
 */
export const SSNInput = ({
        value,
        error,
        onChange,
        autoValidateAndFormat=false,
        ...props
    }) => {
    const controlId = props.controlId || "ssn"
    const label = props.label || "Social Security Number"
    const placeholder = props.placeholder || "333-44-5555"
    const errorMessage = props.errorMessage || "Please provide a valid Social Security number. ITINs are not accepted.";

    const [ssnShown, setSSNShown] = useState(false);

    const toggleSSN = () => setSSNShown(!ssnShown);

    return (
        <div style={outerDivStyle}>
            <div style={inputDivStyle}>
                <Input
                    {...props}
                    controlId={controlId}
                    label={label}
                    placeholder={placeholder}
                    type={ssnShown ? "tel" : "password"}
                    value={value}
                    error={error && errorMessage}
                    onBlur={onChange}
                    onChange={onChange}
                    autoValidateAndFormat={autoValidateAndFormat}
                    validator={validateSSN}
                    formatter={formatSSN}
                    unformatter={val => val.replace(/\D/g, '')}
                />
            </div>
            <div
                style={buttonDiv}
                id='showSSN'
                onClick={toggleSSN} >
                {ssnShown ? uiShow : uiHide}
            </div>
        </div>
    );
};

SSNInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,

};

const buttonDiv = {
    marginTop: '40px',
    marginLeft: '5px',
};

const outerDivStyle = {
    display: 'flex',
    flexFlow: 'row',
}

const inputDivStyle = {
    flexGrow: '1'
}
